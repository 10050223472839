<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>备课统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <summaryChart :loading='loading' :item ='summary_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <a-card size="small" title="员工备课统计">
                        <a-button slot="extra" @click="toExport" size="small" icon="export" :loading="exportLoading">导出</a-button>
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
                        :columns="col_teacher_summary_columns" :dataSource="col_teacher_summary" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card>
                    </a-col>
                    <!-- <a-col :lg="24" :xl="12">
                        <LRanking title="教室排课数" :rank_data="roomS" :loading="loadingS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> 
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="学员上课统计" :rank_data="roomB" :loading="loadingB" :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> 
                    </a-col> -->
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import summaryChart from './summaryChart'
// import roomSummate from './roomSummate'
// import roomBook from './roomBook'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'summary',

  data() {
    return {
      loading:false,
      loadingS:false,
      loadingB:false,
      visible: false,
      exportLoading:false,
      col_teacher_summary:[],
      col_teacher_summary_columns:[],
      summary_data:{},
      counts:[],
      studios:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  components:{
    summaryChart,
    // roomBook,
    // roomSummate,
    statistics
  },
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
  },
  computed: {
      RelativeWidth() {
          let width = 850
          if(this.filedLength >10){
          width = 900 + ( this.filedLength - 10 ) * 130
          }
          return width
      }
  },
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      // this.getCount(obj)
      this.getRoom(obj)
      // this.getRoomSummate(obj)
      // this.getRoomBook(obj)
    },

    async getRoom(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisEducationSummaryAction', obj)
      console.log(res.data.summary);
      this.summary_data = res.data.summary

      let single_col_date = 
       [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '员工名称', width:'160px', dataIndex: 'employee_name',  key: 'employee_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'120px', dataIndex: 'employee_summary_total', key: 'employee_summary_total',fixed: 'left',align:'right'},
      ]
      if(res.data.employee_sumnary_table){
          res.data.employee_sumnary_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
              single_col_date.push(obj)
          })
      }

      this.col_teacher_summary_columns=single_col_date;
      this.col_teacher_summary = res.data.employee_sumnary_table.rows;
      
      this.loading = false
    },
    // async getRoomSummate(obj){
    //   this.loadingS = true
    //   let resS = await this.$store.dispatch('analysisRoomSummateAction', obj)
    //   this.roomS = resS.data
    //   this.loadingS = false
    // },
    // async getRoomBook(obj){
    //   this.loadingB = true
    //   let resB = await this.$store.dispatch('analysisRoomBookAction', obj)
    //   this.roomB = resB.data
    //   this.loadingB = false
    // },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisRoomCountAction', obj)
      this.counts = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    async toExport(){
        this.exportLoading = true
        let exportData = {
            search:this.paramsData
        }
        let res = await this.$store.dispatch('analysisEducationSummaryExportAction', exportData)
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `员工备课统计.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }

        this.exportLoading = false
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>